/*------------------------------------------------*/
/*	Skin: Green, Light Nav
/*------------------------------------------------*/
/* top bar */
.top-bar {
  background-color: #94c632;
  border-bottom-color: #769d28;
}
.top-bar .action-group .btn-group > a {
  color: #85b22d;
}
.top-bar .action-group .action-item > a {
  color: #85b22d;
}
.top-bar .action-group .action-item > a:hover, .top-bar .action-group .action-item > a:active, .top-bar .action-group .action-item > a.active {
  color: #fff;
}
.top-bar .logged-user .btn-group > a {
  color: #fff;
}
.top-bar .logged-user .btn img {
  border-color: #fff;
}
.top-bar .action-group .btn-group > a:hover,
.top-bar .logged-user .btn-group > a:hover {
  color: #fff;
}
.top-bar .action-group .btn-group.open > a,
.top-bar .logged-user .btn-group.open > a {
  color: #fff;
}

/* minified and off-canvas toggle button */
.btn-nav-sidebar-minified i,
.btn-off-canvas i {
  color: #fff;
}
.btn-nav-sidebar-minified i:hover,
.btn-off-canvas i:hover {
  color: #fff;
}

/* custom nav pills */
.nav-pills-custom-minimal > li.active a {
  border-top-color: #94c632;
}
.nav-pills-custom-minimal > li.active a:hover, .nav-pills-custom-minimal > li.active a:focus {
  border-top-color: #94c632;
}
.nav-pills-custom-minimal.custom-minimal-bottom > li.active a {
  border-bottom-color: #94c632;
}
.nav-pills-custom-minimal.custom-minimal-bottom > li.active a:hover, .nav-pills-custom-minimal.custom-minimal-bottom > li.active a:focus {
  border-bottom-color: #94c632;
}

body {
  background: #fff;
}

#col-right {
  border-right: 1px solid #ccc;
}

.main-nav h3 {
  border-bottom: 1px solid #ccc;
}

ul.main-menu li a {
  color: #696565;
}
ul.main-menu li a:hover, ul.main-menu li a:focus {
  background-color: #e7e7e7;
  color: #696565;
}
ul.main-menu li.active > a {
  background-color: #e7e7e7;
  color: #696565;
}
ul.main-menu li.active > a.submenu-toggle {
  background-color: #fff;
  color: #696565;
}
ul.main-menu .sub-menu > li a,
ul.main-menu .sub-menu .sub-menu > li a {
  background-color: #fff;
}
ul.main-menu .sub-menu > li a:hover, ul.main-menu .sub-menu > li a:focus,
ul.main-menu .sub-menu .sub-menu > li a:hover,
ul.main-menu .sub-menu .sub-menu > li a:focus {
  background-color: #e7e7e7;
}
ul.main-menu .sub-menu > li.active > a,
ul.main-menu .sub-menu .sub-menu > li.active > a {
  background-color: #e7e7e7;
}
ul.main-menu .sub-menu > li.active > a:hover, ul.main-menu .sub-menu > li.active > a:focus,
ul.main-menu .sub-menu .sub-menu > li.active > a:hover,
ul.main-menu .sub-menu .sub-menu > li.active > a:focus {
  background-color: #e7e7e7;
}
ul.main-menu .sub-menu > li.has-submenu a,
ul.main-menu .sub-menu .sub-menu > li.has-submenu a {
  background-color: #fff;
}

.main-nav-minified #col-left ul.main-menu li > a:hover {
  background-color: #e7e7e7;
}
.main-nav-minified #col-left ul.main-menu > li:hover ul.sub-menu {
  -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  height: auto !important;
}
