.signature-background-container,
.survey-background-container {
    height: 100%;
    left: 0;
    opacity: 1;
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: opacity 1s ease 0s;
    width: 100%;
    z-index: 0;

    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -moz-animation: fadein 2s; /* Firefox */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera */
    animation: fadein 2s;
}

.signature-background-image {
    background-image: url("../img/signature_bg_body.jpg") !important;
}

.survey-background-image {
    background-image: url("../img/survey_bg_body.jpg") !important;
}

.signature-background-image,
.survey-background-image {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #fff;
    display: block;
    height: 100%;
    visibility: visible;
    margin-right: 500px;

    -webkit-animation: fadein 4s; /* Safari and Chrome */
    -moz-animation: fadein 4s; /* Firefox */
    -ms-animation: fadein 4s; /* Internet Explorer */
    -o-animation: fadein 4s; /* Opera */
    animation: fadein 4s;
}

.signature_panel,
.survey_panel {
    padding: 30px;
    border-left: 1px solid #ccc;
}

.signature_panel .footer-wrap,
.survey_panel .footer-wrap {
    position: absolute;
    bottom: 0;
}

@media only screen and (max-width: 500px) {
    .signature-background-container,
    .survey-background-container {
        display: none;
    }

    .signature_panel,
    .survey_panel {
        width: 100%;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari and Chrome */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

​

/* Opera */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

​