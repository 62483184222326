
.m-signature-pad {
    position: relative;
    font-size: 10px;
    height: 305px;
}

.m-signature-pad--body {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 5px;
    bottom: 80px;
    border: 1px solid #f4f4f4;
}

.m-signature-pad--body canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
    background: url("/img/horizontal_line.png") center 70%  no-repeat;
    cursor: url("/img/pen-icon.png") 0 25, auto;
}

.m-signature-pad--footer {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 15px;
    height: 60px;
}

.m-signature-pad--footer .description,
.m-signature-pad--footer .err-description {
    color: #C3C3C3;
    text-align: center;
    font-size: 13px;
    margin-top: 10px;
}

.m-signature-pad--footer .left, .m-signature-pad--footer .right {
    position: absolute;
    bottom: 0;
}

.m-signature-pad--footer .left {
    left: 0;
}

.m-signature-pad--footer .right {
    right: 0;
}

@media screen and (max-width: 1024px) {
    .m-signature-pad {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        min-width: 250px;
        min-height: 140px;
        margin: 5%;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .m-signature-pad {
        margin: 10%;
    }
}

@media screen and (max-height: 320px) {
    .m-signature-pad--body {
        left: 0;
        right: 0;
        top: 0;
        bottom: 32px;
    }
    .m-signature-pad--footer {
        left: 20px;
        right: 20px;
        bottom: 4px;
        height: 28px;
    }
    .m-signature-pad--footer .description,
    .m-signature-pad--footer .err-description {
        font-size: 1em;
        margin-top: 1em;
    }
}
