.take-survey-widget .question-item {
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    padding: 10px 15px 0;
    margin: 0 0 20px 0;

}

.take-survey-widget textarea {
    resize: none;
}

.take-survey-description {
    padding: 0 15px;
    margin-bottom: 40px;
    text-align: justify;
}

.rating-list {
    display: inline-block !important;
}

.fancy-radio.rating-list input[type="radio"] + span {
    margin-right: 0 !important;
}

.fancy-radio.rating-list input[type="radio"] + span i {
    margin-right: 0 !important;
}

.rating-list-item {
    display: flex;
    align-items: center;
}

.question-item .rating-list-item:nth-child(odd) {
    background-color: #f2f2f2;
}

.question-item .rating-list-item:nth-child(even) {
    background-color: #ffffff;
}

.required label.rating-list-label:after {
    content: " *";
    color: red;
}

.error-color{
    color: #a94442;
}

.rating-list-error {
    color: #a94442;
    padding-top: 10px;
    padding-bottom: 10px;
}
