.grecaptcha-badge {
    z-index: 100;
}

.required label::after {
    color: #a94442;
    content: ' *';
    display: inline;
}

.table.patient-insurance-trips > tbody > tr > th {
    border-top: none;
    border-bottom: 1px solid black;
}

.p-0 {
    padding: 0;
}