@font-face {
    font-family: 'AntroVectra';
    src: url('../fonts/antro_vectra/AntroVectra.eot?v=4.2.0');
    src: url('../fonts/antro_vectra/AntroVectra.eot?#iefix&v=4.2.0') format('embedded-opentype'), url('../fonts/antro_vectra/AntroVectra.woff?v=4.2.0') format('woff'), url('../fonts/antro_vectra/AntroVectra.ttf?v=4.2.0') format('truetype'), url('../fonts/antro_vectra/AntroVectra.svg?v=4.2.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Beautiful Creatures';
    src: url('../fonts/beautifulCreatures/Beautiful Creatures.eot');
    src: url('../fonts/beautifulCreatures/Beautiful Creatures.eot') format('embedded-opentype'), url('../fonts/beautifulCreatures/BeautifulCreatures.woff') format('woff'), url('../fonts/beautifulCreatures/BeautifulCreatures.ttf') format('truetype'), url('../fonts/beautifulCreatures/BeautifulCreatures.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Dawning a new Day';
    src: url('../fonts/dawningOfANewDay/DawningofaNewDay.eot');
    src: url('../fonts/dawningOfANewDay/DawningofaNewDay.eot') format('embedded-opentype'), url('../fonts/dawningOfANewDay/DawningofaNewDay.woff') format('woff'), url('../fonts/dawningOfANewDay/DawningofaNewDay.ttf') format('truetype'), url('../fonts/dawningOfANewDay/DawningofaNewDay.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Give you glory';
    src: url('../fonts/giveYouGlory/GiveYouGlory.eot');
    src: url('../fonts/giveYouGlory/GiveYouGlory.eot') format('embedded-opentype'), url('../fonts/giveYouGlory/GiveYouGlory.woff') format('woff'), url('../fonts/giveYouGlory/GiveYouGlory.ttf') format('truetype'), url('../fonts/giveYouGlory/GiveYouGlory.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cedarville Cursive';
    src: url('../fonts/cedarvilleCursive/Cedarville-Cursive.eot');
    src: url('../fonts/cedarvilleCursive/Cedarville-Cursive.eot') format('embedded-opentype'), url('../fonts/cedarvilleCursive/Cedarville-Cursive.woff') format('woff'), url('../fonts/cedarvilleCursive/Cedarville-Cursive.ttf') format('truetype'), url('../fonts/cedarvilleCursive/Cedarville-Cursive.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

.font {
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.antro-vectra {
    font: normal normal normal 18px/22px "AntroVectra";
}

.beautiful-creatures {
    font: normal normal normal 22px/22px "Beautiful Creatures";
}

input.beautiful-creatures {
    font-size: 40px;
    line-height: 40px;
}

.dawning-a-new-day {
    font: normal normal normal 12px/22px "Dawning a new Day";
}

input.dawning-a-new-day {
    font-size: 26px;
    line-height: 40px;
}

.give-you-glory {
    font: normal normal normal 12px/22px "Give you glory";
}

input.give-you-glory {
    font-size: 26px;
    line-height: 40px;
}

.cedarville-cursive {
    font: normal normal normal 12px/22px "Cedarville Cursive";
}

input.cedarville-cursive {
    font-size: 24px;
    line-height: 40px;
}