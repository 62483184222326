.question-widget {
    margin-top: 10px;
}

.question-item {
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 10px;
}

.question-item .remove-question:link {
    font-size: 20px;
    position: absolute;
    top: 1px;
    right: 8px;
    color: red;
    opacity: .2;
    transition: all .3s ease;
}

.question-item .remove-question:hover {
    color: red;
}

.question-item .remove-question:active {
    color: red;
}

.question-item:hover .remove-question {
    opacity: 1;
}

.question-item .add-question {
    margin: 5px 0 5px 0;
}

.question-item .control-label {
    padding-left: 0;
}

.question-item .answer-choice {
    margin-top: 15px;
}

.item-row .choice-btn {
    color: white;
    padding: 5px 10px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 15px;
    opacity: 1;
}

.item-row .add-choice {
    background-color: #147da0;
    border-color: #106581;
}

.item-row .remove-choice {
    background-color: #ef2020;
    border-color: #e51010;
}

.item-row .add-choice:hover {
    opacity: .8;
}

.item-row .remove-choice:hover {
    opacity: .8;
}

.checkbox-label {
    padding-top: 2px !important;
}

@media only screen and (min-width: 992px) {
    .text-question-field {
        padding-left: 10px;
    }

    .question-value-padding {
        padding-left: 0;
        padding-right: 0;
    }

    .question-item .answer-choice {
        padding-left: 20px;
    }
}

@media only screen and (max-width: 991px) {
    .text-question-field {
        padding-left: 15px;
    }

    .question-value-padding {
        padding-left: 0;
        padding-right: 0;
    }

    .question-item .answer-choice {
        padding-left: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .text-question-field {
        padding-left: 0;
        padding-right: 0;
    }

    .xs-side-padding-0 {
        padding-left: 0;
        padding-right: 0;
    }
}

